import { AnimatedCoins } from './animated-numbers';
import Image from 'next/image';
import Typography, { type TypographyAffects } from './ui/typography';
import { cn } from 'utils/shadcn';
import coin from 'public/coin.png';
import formatCoins from 'utils/format/coins';

export default function Coins({
  value,
  animated = false,
  affects = 'small',
  className,
}: {
  value: number;
  animated?: boolean;
  affects?: TypographyAffects;
  className?: string;
}) {
  return (
    <Typography
      variant="span"
      affects={affects}
      className={cn(
        'pointer-events-none flex items-center gap-[0.25em] text-nowrap font-bold',
        className,
      )}
    >
      <Image
        src={coin}
        alt="Coin"
        width={32}
        height={32}
        className="size-[1em]"
      />
      {animated && <AnimatedCoins value={value} animated={animated} />}
      {!animated && (
        <span className="tracking-tight">{formatCoins(value)}</span>
      )}
    </Typography>
  );
}
