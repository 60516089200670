import BoxCardVolatility from 'components/box-card-volatility';
import Coins from 'components/coins';
import Image from 'next/image';
import Link from 'next/link';
import Typography from 'components/ui/typography';

export type BoxProps = {
  image: string;
  price: number;
  name: string;
  priority?: boolean;
  color: string;
  cov: number;
  pathname: string;
};

export default function Box({
  image,
  price,
  name,
  priority = false,
  color,
  cov,
  pathname,
}: BoxProps) {
  return (
    <div
      className="group relative flex flex-col items-center justify-center rounded-lg"
      style={{
        background: 'rgba(21, 22, 26, 0.22)',
      }}
    >
      <Link
        href={pathname}
        className="z-10 flex h-full w-full flex-col items-center justify-center"
      >
        <div className="relative aspect-square w-full transition-transform scale-100 group-hover:scale-110">
          <Image
            src={image}
            alt={name}
            width={512}
            height={512}
            className="object-cover"
            sizes="(max-width: 767px) 40vw, (max-width: 895px) 25vw, (max-width: 1071px) 20vw, (max-width: 1247px) 17vw, 170px"
            priority={priority}
          />
        </div>
        <div className="flex w-full grow flex-col items-center justify-end gap-3 px-2 pb-0 pt-4">
          <Typography variant="p" affects="small" className="text-center">
            {name}
          </Typography>
        </div>
        <Divider className="my-3" />
      </Link>
      <span className="grid w-full grid-cols-12 items-center overflow-hidden">
        <div className="relative z-0 col-span-3 flex items-center justify-center">
          <BoxCardVolatility cov={cov} />
        </div>
        <div className="bg-frost col-span-6 flex justify-center rounded-sm p-2">
          <Coins value={price} affects="xs" />
        </div>
      </span>
      <div className="absolute -z-10 h-full w-full overflow-hidden rounded-lg">
        <div
          className="absolute -z-10 rounded-[500%] blur-3xl transition-transform translate-y-0 scale-150 group-hover:-translate-y-10"
          style={{
            background: color,
            width: `${25200 / 170}%`,
            height: `${14700 / 290}%`,
            top: `${31400 / 290}%`,
            left: `${-4100 / 170}%`,
          }}
        />
      </div>
    </div>
  );
}

export function BoxSkeleton() {
  return (
    <div
      className="group relative flex flex-col items-center justify-center rounded-lg"
      style={{
        background: 'rgba(21, 22, 26, 0.22)',
      }}
    >
      <div className="z-10 flex h-full w-full flex-col items-center justify-center">
        <div className="relative aspect-square w-full">
          <div className="absolute inset-0 animate-pulse rounded-2xl bg-gray-700/50" />
        </div>
        <div className="flex w-full grow flex-col items-center justify-end gap-3 px-2 pb-0 pt-4">
          <div className="h-4 w-24 animate-pulse rounded-md bg-gray-700/50" />
        </div>
        <div
          className="bg-frost my-3 animate-pulse rounded-full"
          style={{ width: '72px', height: '16px' }}
        />
      </div>
      <span className="grid w-full grid-cols-12 items-center overflow-hidden">
        <div className="col-span-3 place-self-center">
          <div className="h-6 w-6 animate-pulse rounded-full bg-gray-700/50" />
        </div>
        <div className="bg-frost col-span-6 flex justify-center rounded-sm p-2">
          <div className="h-4 w-16 animate-pulse rounded-md bg-gray-700/50" />
        </div>
      </span>
      <div className="absolute -z-10 h-full w-full overflow-hidden rounded-lg">
        <div
          className="absolute -z-10 rounded-[500%] blur-3xl"
          style={{
            background: 'rgba(184, 161, 113, 0.1)',
            width: `${25200 / 170}%`,
            height: `${14700 / 290}%`,
            top: `${31400 / 290}%`,
            left: `${-4100 / 170}%`,
          }}
        />
      </div>
    </div>
  );
}

function Divider(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={72}
      height={16}
      fill="none"
      {...props}
    >
      <path stroke="url(#left)" strokeLinecap="round" d="M1 8h20" />
      <path stroke="url(#right)" strokeLinecap="round" d="M71 8H51" />
      <path
        fill="#B8A171"
        fillRule="evenodd"
        d="M30.414 9.486 29 8.07l1.414-1.414 4.172-4.171L36 1.07l1.414 1.415 4.172 4.171L43 8.071l-1.414 1.415-4.172 4.171L36 15.071l-1.414-1.414-4.172-4.171ZM43 8.07l2-2 2 2-2 2-2-2Zm-14 0-2 2-2-2 2-2 2 2Zm7 4.172 4.172-4.172L36 3.9 31.828 8.07 36 12.243Zm0-6.172-2 2 2 2 2-2-2-2Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="left"
          x1={1}
          x2={21}
          y1={8}
          y2={8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#504521" stopOpacity={0} />
          <stop offset={0.635} stopColor="#B6A66F" stopOpacity={0.965} />
          <stop offset={1} stopColor="#E4D8AC" />
        </linearGradient>
        <linearGradient
          id="right"
          x1={51}
          x2={71}
          y1={8}
          y2={8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4D8AC" />
          <stop offset={0.365} stopColor="#B6A66F" stopOpacity={0.965} />
          <stop offset={1} stopColor="#504521" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
