'use client';

import { use, useCallback, useMemo } from 'react';

import type { Box } from './types';
import BoxComponent from './box';
import Grid from './grid';
import Typography from 'components/ui/typography';

export default function SearchableBoxGrid({
  boxes,
  tag,
  query,
}: {
  boxes: Box[];
  tag?: string;
  query?: string;
}) {
  const filter = useCallback(
    async (term?: string, tag?: string) => {
      if (!term && !tag) return boxes;

      const boxesWithTag = tag
        ? boxes.filter((box) => box.tags.includes(tag))
        : boxes;

      const Fuse = (await import('fuse.js')).default;
      const fuse = new Fuse(boxesWithTag, {
        keys: ['name', 'items.name', 'items.displayProperties.value'],
        threshold: 0.3,
        isCaseSensitive: false,
      });

      let filteredResults = boxesWithTag;

      if (term) {
        const searchResults = fuse.search(term).map((result) => result.item);
        filteredResults = searchResults;
      }

      return filteredResults;
    },
    [boxes],
  );

  const filteredBoxes = useMemo(() => filter(query, tag), [filter, query, tag]);
  const awaited = use(filteredBoxes);

  return (
    <Grid>
      {awaited.map((box, index) => (
        <BoxComponent
          {...box}
          key={box.id}
          color={box.color}
          cov={box.cov}
          priority={index < 7}
        />
      ))}
      {awaited.length === 0 && (
        <Typography variant="p" affects="small">
          No results found.
        </Typography>
      )}
    </Grid>
  );
}
